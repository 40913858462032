import React, { useEffect, useState } from "react";
import { GlobalFooterLargeCtaHType } from "../assets/Types/AcfGroupTypes/GlobalFooterLargeCtaHType.type";
import { GlobalFooterLargeCtaO, pendingTrackEvents } from "@types";
import { MainProvider } from "../contexts/MainContext";
import { mxpPageViewed } from "../utils/mixpanelEvents/mixpanelEvents";
import generateUuId from "../utils/generateUuId";
import GlobalFooterLargeCta from "../components/CTAs/GlobalFooterLargeCta/GlobalFooterLargeCta";
import useGtmPageChange from "../hooks/useGtmPageChange";
import GABannerSection from "@components/GABannerSection";
import "./main.scss";
import DefaultFooter from "./DefaultFooter";
import PrimaryNavMenuWrapper from "@components/RedesignComponents/BuilderWrappers/PrimaryNavMenu/PrimaryNavMenu";
import FooterWrapper from "@components/RedesignComponents/BuilderWrappers/FooterWrapper/FooterWrapper";

declare global {
  interface Window {
    mixpanelTrialSent: boolean;
    trackMixpanelEvent: (
      event: string,
      data?: {
        [key: string]: any;
      }
    ) => void;
    grecaptcha: any;
    dataLayer: {
      push: (someObj: Object) => boolean;
    };
    gtag: any;
    fbq: any;
    google_conversion_first_name: string;
    google_conversion_last_name: string;
    google_conversion_email: string;
    google_conversion_phone_number: string;
    Grnhse: {
      Iframe: {
        load: (jobId?: number) => void;
      };
    };
    growsumo?: {
      cookie_domain: string;
      domain_v1: string;
      domain_v2: string;
      fallback_domain: string;
      initialized: boolean;
      data: {
        amount: number;
        currency: string;
        customer_key: string;
        email: string;
        host_domain: string;
        name: string;
        public_key: string;
      };
      createSignup: () => void;
    };
    Osano: {
      cm: {
        showDrawer: (s: string) => void;
        showDoNotSell: () => void;
      };
    };
    DG_BANNER_API: {
      showConsentBanner: () => void;
    };
    dgEvent: {
      push: (someObj: Object) => boolean;
    };
    pendingMxpEvents: pendingTrackEvents[];
    pendingSegmentEvents: pendingTrackEvents[];
  }
}

type MainLayoutPropsType = {
  alter?: MainLayoutTypes.alter;
  children: JSX.Element | JSX.Element[];
  FooterLargeCtaData?: GlobalFooterLargeCtaO;
  globalFooterLargeH?: GlobalFooterLargeCtaHType;
  hasFooterLargeCta?: boolean;
  hasNoDivider?: boolean;
  hasNoFooter?: boolean;
  hasNoFooterLinks?: boolean;
  hasNoHeader?: boolean;
  isTracked?: boolean;
  noStickyHeader?: MainLayoutTypes.noSticky;
  page?: MainLayoutTypes.PageVariantType;
  smartDisplayToggle?: string;
  preventRerender?: boolean;
  pathname: string;
  isRebrandNav?: boolean;
};

const MainLayout: React.FC<MainLayoutPropsType> = ({
  children,
  FooterLargeCtaData,
  hasFooterLargeCta,
  hasNoFooter,
  hasNoFooterLinks,
  hasNoHeader,
  isTracked = true,
  noStickyHeader = false,
  page,
  globalFooterLargeH,
  hasNoDivider,
  preventRerender,
  pathname,
  isRebrandNav,
}) => {
  const uuid = generateUuId();

  useEffect(() => {
    if (!preventRerender) setIsClient(true);
    if (isTracked) {
      mxpPageViewed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useGtmPageChange(uuid);

  const [isClient, setIsClient] = useState(false);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        {!hasNoHeader && (
          <>
            <GABannerSection />
            <PrimaryNavMenuWrapper />
          </>
        )}
        <div>
          <main>
            {children}
            {hasFooterLargeCta && (
              <GlobalFooterLargeCta
                LargeCtaData={
                  globalFooterLargeH &&
                  globalFooterLargeH.smartDisplayToggle === "on"
                    ? globalFooterLargeH
                    : FooterLargeCtaData
                }
              />
            )}
          </main>
          {!hasNoFooter ? (
            isRebrandNav ? (
              <FooterWrapper />
            ) : (
              <DefaultFooter pathname={pathname} />
            )
          ) : (
            <></>
          )}
        </div>
      </React.Fragment>
    </MainProvider>
  );
};

export default MainLayout;
